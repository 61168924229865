import React from 'react';
import Footer from '../components/footer';

const NotFoundPage = () => (
  <div>
    <div
      className="portfolio-wrapper"
      style={{
        minHeight: '70vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </div>
    <Footer />
  </div>
);

export default NotFoundPage;
